import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { 
  Tabs, Tab, Tooltip,
  Button, Select, TextField, 
  Card, CardContent,
  Typography,
} from '@mui/material';

import Page from 'shared/AppPage';
import TabPanel from 'shared/TabPanel';

import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';

import PricingRuleItem from './components/PricingRuleItem';
import PricingRuleProductLookup from './components/PricingRuleProductLookup';
import PricingRuleManualPricingBatch from './components/ManualPricingBatch';
import ProductVariantExportWithPricingRules from './components/ProductVariantExportWithPricingRules';

import axios from 'utils/axios-rails';

import { useMuiNotification, buildFormData } from 'utils';
import AppNotification from 'shared/AppNotification';

const propTypes = {
  pricingRules: PropTypes.array.isRequired,
  
  createURL: PropTypes.string.isRequired,
  exportItemsURL: PropTypes.string.isRequired,
  uploadPriceFileURL: PropTypes.string.isRequired,
  lookupURL: PropTypes.string.isRequired,
  dispatchProductBatchesURL: PropTypes.string.isRequired,

  defaultPricingRule: PropTypes.object.isRequired,

  productCategories: PropTypes.array.isRequired,
  productBrands: PropTypes.array.isRequired,
  exchangeRate: PropTypes.shape({
    crc: PropTypes.number.isRequired,
    last_updated_at: PropTypes.string.isRequired,
  })
}

const defaultProps = {

}

const rulesKinds = {
  markup: "markup",
  smart_price: "smart_price",
  manual_flex: "manual_flex",
  manual_only: "manual_only",
}

const PricingRuleFormContainer = (props) => {
  const [loading, setLoading] = useState(false);
  const [sendNotification, notificationProps] = useMuiNotification();

  const [tabActive, setTabActive] = useState(0);
  const [formId, setFormId] = useState(0); // virtual id to find and delete non yet created rules (initialized on groupRules as well)
  const [groupedPricingRules, setGroupedPricingRules] = useState({ markup: [], smart_price: [], manual: [] });

  const handleTabChange = useCallback ((event, value) => { setTabActive(value) }, []);

  // ADD
  // Kind must match backend value
  const addNewRule = (kind) => {
    let groupName = kind;
    let newItem = { ...props.defaultPricingRule, kind: kind, formId: formId, manual_file: null }

    switch(kind) { // markup and smart_price share the same kind name as the filtered group
      case rulesKinds.markup:
        newItem = { ...newItem, markup_value: 0 };
        break;
      case rulesKinds.smart_price:
        break;
      case rulesKinds.manual_only: 
      case rulesKinds.manual_flex:
        groupName = "manual"; // manual rules are grouped together when filtering
        break;
      default:
        alert("Se intento agregar una regla desconocida, recargue la página e intente de nuevo");
        console.error("Unknown rule", kind);
        break;
    }

    const newRules = [ ...groupedPricingRules[groupName], newItem ];

    setGroupedPricingRules({
      ...groupedPricingRules,
      [groupName]: newRules,
    });

    setFormId(formId + 1);
  };

  const addNewRuleMarkup = () => addNewRule(rulesKinds.markup);
  const addNewRuleSmartPrice = () => addNewRule(rulesKinds.smart_price);
  const addNewRuleManual = () => addNewRule(rulesKinds.manual_only); // initialized as manual_only, manual_flex depends on form

  // Receive a pricingRule data and current Map(hash) of pricingRules and return information of the rule on the collection
  const findRule = useCallback( (pricingRule, rulesMap) => {
    let groupName = null;
    if(pricingRule.kind === rulesKinds.manual_flex || pricingRule.kind === rulesKinds.manual_only) {
      groupName = "manual";
    }
    else{
      groupName = pricingRule.kind;
    }
    const groupCollection = rulesMap[groupName];

    const ruleIndex = groupCollection.findIndex(rule => rule.formId === pricingRule.formId);
    const foundRule = groupCollection[ruleIndex]; // for debugging? not actually required

    return { groupName, groupCollection, index: ruleIndex, item: foundRule };
  } , []);

  // DELETE
  const handleDelete = async (pricingRule) => {
    const { groupName, groupCollection, index: ruleIndex, item: ruleToBeDeleted } = findRule(pricingRule, groupedPricingRules);
    
    let successfulDelete = true;
    //optimistic update
    const updatedRule = { ...ruleToBeDeleted, deleted: true }; // filtering out is a bit bugged since UI still preserve positional values, just flag deleted ones and hide.

    const newRules = [ 
      ...groupCollection.slice(0, ruleIndex), 
      updatedRule,
      ...groupCollection.slice(ruleIndex + 1),
    ]; // keep elements before and after index

    // if id remote delete
    setGroupedPricingRules({
      ...groupedPricingRules,
      [groupName]: newRules,
    })

    if(ruleToBeDeleted.id){ // exists on backend
      try{
        const { data } = await axios.delete(`${props.createURL}/${ruleToBeDeleted.id}`);
        if(data.success){
          sendNotification({ message: "Eliminado exitosamente", severity: "success" });
        }
        else{
          successfulDelete = false;
          sendNotification({ message: "Error al eliminar", severity: "error" });
        }
      }
      catch(error){
      }
    } // else just remove from local

    if(!successfulDelete){ // rollback UI update
      setGroupedPricingRules(groupedPricingRules);
    }
  };

  const handleProductsExport = async (pricingRule, email) => {
    let success = false;
    try {
      const { data } = await axios.post(props.exportItemsURL.replace(":id", pricingRule.id), { email });
      if(data.success){
        success = true;
        sendNotification({ message: "Exportación exitosa", severity: "success" });
      }
      else{
        sendNotification({ message: data.error, severity: "error" });
      }
    } catch (error) {
      sendNotification({ message: "Error de conexión", severity: "error" });
    }

    return success;
  };

  const handleBrandFileUpload = async (pricingRule, file) => {
    setLoading(true);
    let success = false;
    try {
      const formData = new FormData;
      formData.append("file", file);
      const { data} = await axios.post(props.uploadPriceFileURL.replace(":id", pricingRule.id), formData);

      if(data.success){
        success = true;
        sendNotification({ message: "Carga exitosa", severity: "success" });
      }
      else{
        sendNotification({ message: data.error, severity: "error" });
      }
    } catch (error) {
      console.log("EEE", error);
      sendNotification({ message: "Error de conexión", severity: "error" });
    }
    
    setLoading(false);
    return success;
  }

  // SAVE
  const handleSave = async (values, formikBag) => {
    console.log("save", values);
    let remoteRecord = null;
    try {
      // const params = { pricing_rule: values };
      const params = buildFormData({ parentName: "pricing_rule", values, fileAliases: { "manual_file_blob": "manual_file" } }); // FormData to allow file upload on Manual rules
      const { data } = !!values.id ? await axios.patch(`${props.createURL}/${values.id}`, params) : await axios.post(props.createURL, params); // if id exists update
      if(data.success){
        remoteRecord = data.record;
        sendNotification({ message: "Guardado exitosamente", severity: "success" })
      }
      else{
        console.log("errors", data.errors);
        formikBag.setErrors(data.errors);
        sendNotification({ message: "Error al guardar", severity: "error" })
      }
    } catch (error) {
      console.warn("Error saving rule", error);   
    }

    if(!!remoteRecord){ // local save into state
      const { groupName, groupCollection, index: ruleIndex, item: currentRule } = findRule(values, groupedPricingRules);

      const updatedRule = { ...currentRule, ...remoteRecord, manual_file_blob: null }; // insert DB Id and others data like user

      const newRules = [ 
        ...groupCollection.slice(0, ruleIndex), 
        updatedRule,
        ...groupCollection.slice(ruleIndex + 1) 
      ];

      setGroupedPricingRules({
        ...groupedPricingRules,
        [groupName]: newRules,
      });
    }
    else{
      
    }
  }

  // Initialization
  // Should be run once
  const setRulesGrouping = useCallback((rules) => {
    let groupedRules = { markup: [], smart_price: [], manual: [] };
    console.log("R", rules);
    rules.forEach((rule, index) => {
      const ruleWithFormId = { ...rule, formId: index };
      switch(rule.kind){
        case rulesKinds.markup: groupedRules.markup.push(ruleWithFormId); break;
        case rulesKinds.smart_price: groupedRules.smart_price.push(ruleWithFormId); break;
        case rulesKinds.manual_only: case rulesKinds.manual_flex: groupedRules.manual.push(ruleWithFormId); break;
      }
    });

    setFormId(rules.length + 1); // for new rules created onward on this screen
    setGroupedPricingRules(groupedRules);
    return groupedRules;
  }, []);

  // Group rules to display
  useEffect(() => {
    setRulesGrouping(props.pricingRules);
  }, []);

  console.log("Grouped", groupedPricingRules);
  console.log("Props", props);

  const itemProps = {

    onSave: handleSave,
    onDelete: handleDelete,
    onProductsExport: handleProductsExport,
    onBrandFileUpload: handleBrandFileUpload,
    loading: loading,

    productCategories: props.productCategories,
    productBrands: props.productBrands,
  }

  return (
    <Page title="Consola de Manejo de Precios">
        { props.exchangeRates.crc &&
          <Typography color='GrayText' className='mb8'>
            <span>Tipo cambio Dolar: { props.exchangeRates.crc }</span>
            <span> - Actualizado por última vez: { props.exchangeRates.last_updated_at }</span>
          </Typography>
        }
      <AppNotification
        {...notificationProps}
      />
      <Card>
        <CardContent>
          <Tabs value={ tabActive } onChange={ handleTabChange } aria-label="rules-tabs" variant='scrollable'>
            <Tab label="Método 1: Mark-Up por Categoría" id="pricing-rule-markup" />
            <Tab label="Método 2: Agregar a IN" id="pricing-rule-smart-price" />
            <Tab label="Método 3: MSRP / Manual" id="pricing-rule-manual" />
            <Tab label="Busqueda con Producto" id="pricing-product-lookup" />
            <Tab label="Correr tareas de precio" id="pricing-manual-pricing-batch" />
            <Tab label="Exportar productos con información de precios" id="product-variants-export-with-pricing-rules" />
          </Tabs>

          {/* Markup Tab */}
          <TabPanel value={tabActive} index={0}>
            <Typography color="GrayText">
              Prioridad baja. Reglas corren dos veces al día, 6:00 AM y 6:00 PM.
            </Typography>

            { groupedPricingRules.markup.map( (rule, index) => 
              <PricingRuleItem 
                key={ index } 
                pricingRule={ rule }
                { ...itemProps }
              />
            )}
            <Button onClick={ addNewRuleMarkup } variant='text' startIcon={ <AddIcon/> }>
              Agregar regla
            </Button>
          </TabPanel>
          
          {/* IN Tab */}
          <TabPanel value={tabActive} index={1}>
            <Typography color="GrayText">
              Prioridad media. Reglas corren dos veces al día, 6:00 AM y 6:00 PM. Los productos dentro de las categorías seleccionadas se agregan al feed una vez al día y su precio solo cambia hasta que IN encuentra un match, si no lo encuentra, se usa método 1.
            </Typography>
            <Typography className='flex'>
              ¿Cuáles productos se envían a IN? 
              <Tooltip title={
                <ul>
                  <li>Categorias Seleccionadas Abajo</li>
                  <li>Published (Shopify)</li>
                  <li>Excluir los que estan con MSRP / Manual</li>
                  <li>Excluir los que no tienen match por 6 meses sin match.</li>
                </ul>
              }>
                <InfoIcon/>
              </Tooltip>
            </Typography>

            { groupedPricingRules.smart_price.map( (rule, index) => 
              <PricingRuleItem
                key={ index } 
                pricingRule={ rule }
                { ...itemProps }
              />
            )}
            <Button onClick={ addNewRuleSmartPrice } variant='text' startIcon={ <AddIcon/> }>
              Agregar regla
            </Button>
          </TabPanel>

          {/* Manual Tab */}
          <TabPanel value={tabActive} index={2}>
          <Typography color="GrayText">
              Prioridad alta. Los productos dejan de actualizar apenas se crea la regla. Si se suben precios en un archivo, la actualización corre de inmediato. Tiene mayor prioridad Archivo SKU que Marca.
            </Typography>

            { groupedPricingRules.manual.map( (rule, index) => 
              <PricingRuleItem 
                key={ index } 
                pricingRule={ rule }
                { ...itemProps }
              />
            )}
            <Button onClick={ addNewRuleManual } variant='text' startIcon={ <AddIcon/> }>
              Agregar regla
            </Button>
          </TabPanel>

          <TabPanel value={tabActive} index={3}>
            <PricingRuleProductLookup submitURL={ props.lookupURL } />
          </TabPanel>

          <TabPanel value={tabActive} index={4}>
            <PricingRuleManualPricingBatch 
              submitURL={ props.dispatchProductBatchesURL}
              onSendNotification={ sendNotification }
            />
          </TabPanel>

          <TabPanel value={tabActive} index={5}>
            <ProductVariantExportWithPricingRules />
          </TabPanel>

        </CardContent>
      </Card>
    </Page>
  )
};

PricingRuleFormContainer.propTypes = propTypes;
PricingRuleFormContainer.defaultProps = defaultProps;

export default PricingRuleFormContainer;