import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMuiNotification } from 'utils';
import AppNotification from 'components/shared/AppNotification';

import { TextField, Button, Typography, CircularProgress, Card, CardActions } from '@mui/material';

import { useLazyQuery, gql } from '@apollo/client';
import adminClient from 'api/apollo-client';

import UploadButton from 'components/shared/form/UploadButton';
import TagsInput from 'react-tagsinput';

const propTypes = {

}

const defaultProps = {

}

const ProductVariantExportWithPricingRules = (props) => {
  const [recipients, setRecipients] = useState([]);
  const [inputFileData, setInputFileData] = useState({
    base64: null,
    mimeType: null,
  });
  const [errors, setErrors] = useState([]);

  const [sendNotification, notificationProps] = useMuiNotification();

  const [generateExport, { loading }] = useLazyQuery(PRODUCT_VARIANT_EXPORT_WITH_PRICING_RULES, {
    client: adminClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      sendNotification({ message: "Reporte enviado a los recipientes", severity: "success" });
      setRecipients([]);
      // setInputFileData({ base64: null, mimeType: null }); // Currently UploadButton doesn't have a 'parent' clear method, so if we clear the state without that the UI will sitll show up the file name
    },
    onError: (error) => {
      console.error(error);
      sendNotification({ message: "Error al generar el reporte", severity: "error" });
    }
  });

  const handleSubmit = () => {
    generateExport({
      variables: {
        fileInput: {
          base64: inputFileData.base64,
          mimeType: inputFileData.mimeType,
        },
        recipients,
      }
    })
  }

  const handleFileChange = (file) => {
    setErrors([]);
    // const filename = file.name;
    const reader = new FileReader();
    reader.onload = (e) => { // Get the base64 string and send it to the API
      const readerResult = reader.result; // Format is: data:<media_type>;base64,<base64_string>. i.e. "data:image/jpeg;base64,/9jxxx..."
      const parts = readerResult.split(";base64,");
      const mimeType = parts[0].split(":")[1]; // i.e. "image/jpeg"
      const base64 = parts[1]; // Base64 string
      console.log("Mime", mimeType);

      setInputFileData({ base64, mimeType });
    }
    reader.readAsDataURL(file); // This will trigger the onload event
  }

  const canExport = recipients.length > 0 && inputFileData.base64;

  return (
    <div>
      <AppNotification {...notificationProps} />

      <div className="flex justify-between">
        <Typography color="GrayText" className='mb12'>
          Al subir el archivo se enviará por email a los recipientes ingresados el reporte con la información de cada producto y la última regla de precio aplicada.
        </Typography>

        <CircularProgress size={24} hidden={ !loading } />
      </div>

      <div>
        <TagsInput
          value={ recipients }
          onlyUnique
          addKeys={[188, 9]} // Comma, Tab
          inputProps={{
            placeholder: "Agregar email",
          }}
          addOnPaste
          pasteSplit={
            (data) => { return data.split(',').map(d => d.trim()) }
          }
          onChange={ setRecipients }
          className='react-tagsinput'
        />

        <UploadButton 
          onUpload={ handleFileChange }
          helpText="Solo se aceptan archivos .xlsx y .xls"
        />

        <Button 
          className='mt12'
          fullWidth variant="contained" 
          color='primary' 
          onClick={ handleSubmit } 
          disabled={ loading || !canExport }>
          Cargar
        </Button>
      </div>

      <CardActions>
        <Button
          href='/samples/sample_product_variant_export_with_pricing_rules.xlsx'
          color='info'
        >
          Descargar archivo de muestra
        </Button>
      </CardActions>

      { errors.map((error, index) => (
        <Typography key={ index } color="error">
          { error.message }
        </Typography>
      ))}
    </div>
  )
};

ProductVariantExportWithPricingRules.propTypes = propTypes;
ProductVariantExportWithPricingRules.defaultProps = defaultProps;

const PRODUCT_VARIANT_EXPORT_WITH_PRICING_RULES = gql`
  query($fileInput: MediaFileInput!, $recipients: [String!]!) {
    productVariantExportWithPricingRules(fileInput: $fileInput, recipients: $recipients) {
      success
      errors{
        field
        message
      }
    }
  }
`;

export default ProductVariantExportWithPricingRules;